[data-reach-dialog-content] {
    background-color: #4F5B62 !important;
    z-index: 1006;
    padding-bottom: 50px !important;
}

.bigText.linky {
    color: #61DAFB;
}

.image {
    aspect-ratio: 600/430;
    width: 350px;
    height: calc(430/600 * 350px);
    align-self: center;
    border-radius: 2rem;
    border: 2px solid #fdfdfd;
}

.modal {
    max-width: 410px;
    max-height: 433px;
    margin: auto;
    align-items: center;   
    cursor: pointer;
}

.close-button {
    filter: invert(100%) sepia(3%) saturate(7387%) hue-rotate(224deg) brightness(106%) contrast(98%);
}

.close-button:hover {
    filter: invert(80%) sepia(48%) saturate(1036%) hue-rotate(162deg) brightness(99%) contrast(99%);
}

.image-gallery-thumbnail.active {
    border: 4px solid #fdfdfd !important;
    border-radius: 5px;
}

.image-gallery-thumbnail:hover {
    border: 4px solid #fdfdfd !important;
    border-radius: 5px;
}

.image-gallery-image {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 20px;
    border: 2px solid #fdfdfd;
}

.modal:hover {
    transition: background-color 0.3s ease;
    background-color: #4F5B62;
    border-radius: 25px;
    transform: translate(0, -10px);
    height: calc(100% + 10px);
}

.image-gallery-slide .image-gallery-description {
    bottom: 30px !important;
}

.flexCarouselDiv {
    display: flex;
    margin: auto;
}

.dialog {
    border-radius: 20px;
    border: 4px solid #fdfdfd;
    z-index: 1006;
}
.closeButtonDiv {
    text-align: right;
}
.imageGalleryDiv {
    margin: left;
    margin-left: 2%;
}

.bigText {
    white-space: pre-line;
    color: #fdfdfd;
    padding: 0 2vw;
    line-height: 24px;
}

.titleTextH1 {
    color: #fdfdfd;
    text-align: center;
    font-size: 2.4em;
    padding: 0 2vw 20px 2vw;
}

.image-gallery-icon {
    color: #fdfdfd !important;
}

.image-gallery-icon:hover {
    color: #61DAFB !important;
}

button.image-gallery-icon.image-gallery-left-nav:focus {
    outline: 0;
}

.image-gallery {
    margin: auto;  
    width: 27vw;
    min-width: 540px;
}
@media screen and (max-width: 2500px) {
    .titleTextH1 {
        text-align: left;
    }
}
    
@media screen and (max-width: 2000px) {
    
    [data-reach-dialog-content] {
        width: 1000px;
    }
    .bigText{
        padding: 0 40px;
    }
    .titleTextH1{
        padding: 0 40px 10px 40px;
    }
}

@media screen and (max-width: 1200px) {
    
    [data-reach-dialog-content] {
        width: 85vw;
        margin-top: 4vh;
        margin-bottom: 4vh;
        padding: 20px 10px;
    }
    .image-gallery {
        min-width: 40vw;
    }
    .titleTextH1 {
        font-size: 2em;
    }
    .bigText {
        font-size: 15px;
    }
    .close-button {
        max-width: 25px;
    }
}

@media screen and (max-width: 1100px) {
    .bigText {
        font-size: 14px;
    }
}

@media screen and (max-width: 1450px) {
    .image {
        aspect-ratio: 600/430;
        width: 238px;
        height: calc(430/600 * 238px);
    }
}

@media screen and (max-width: 800px) {
    .titleTextH1 {
        display: none;
    }
}

/*
now is phone/tablet resolution
*/

@media screen and (max-width: 768px) {
    .flexCarouselDiv {
        display: flex;
        flex-direction: column;
    }
    .image-gallery {
        min-width: 60vw;
    }
    [data-reach-dialog-content] {
        padding: 10px 10px !important;
    }
    .bigText {
        padding: 0 15px;
    }
}




@media screen and (max-width: 610px) {
    .image {
        aspect-ratio: 600/430;
        width: 200px;
        height: calc(430/600 * 200px);
        border-radius: 18px;
    }
}


@media screen and (max-width: 530px) {
    .image {
        aspect-ratio: 600/430;
        width: 35vw;
        height: 25vw;
    }

    .tech, .otherTechNearby {
        font-size: 2.8vw;
    }

    .image-gallery {
        min-width: 75vw;
    }

}