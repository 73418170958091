.whole {
    padding: 5vh 0;
}

.titleProjects {
    font-size: 77px;
    scroll-margin-block-start: 25vh;
    scroll-margin-block-end: 25vh;
    padding-bottom: 2vh;
}

.grid {
    display: grid;
    max-width: 800px;
    margin: auto;
    justify-content: center;
    grid-template-columns: 422px 422px 422px;
    grid-auto-rows: 367px;
}


@media screen and (max-width: 1450px) {
    .grid {
        grid-template-columns: 300px 300px 300px;
        grid-auto-rows: 280px;
    }
}

@media screen and (max-width: 950px) {
    .grid {
        grid-template-columns: 300px 300px;
        grid-auto-rows: 280px;
    }
}

@media screen and (max-width: 610px) {
    .grid {
        grid-template-columns: 255px 255px;
        grid-auto-rows: 240px;
    }
}

@media screen and (max-width: 530px) {
    .grid {
        grid-template-columns: 45vw 45vw;
        grid-auto-rows: 48vw;
    }
}