.all {
    opacity: 0;
}

.all.done {
    animation-name : mymove;
    animation-iteration-count : 1;
    animation-direction : normal;
    animation-fill-mode: forwards;
    animation-duration : 0.5s;
    animation-timing-function: cubic-bezier(.3, .88, .72, 1);
    /* try .3 .88 .72 1 */
    /* old .4 0 .2 1 */
}
  
@keyframes mymove {
    from {
        transform: translateY(18vh);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes mymove2 {
    from {
        opacity: 1;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 425px) {
    .all {
        opacity: 1;
    }

    .all.done {
    animation-name : mymove2;
    animation-iteration-count : 1;
    animation-direction : normal;
    animation-fill-mode: forwards;
    animation-duration : 0.5s;
    animation-timing-function: cubic-bezier(.3, .88, .72, 1);
    }
}