.experience {
    padding: 7vh 0 10vh 0;
}
.experienceTitle {
    font-size: 77px;
    padding-bottom: 50px;
}

.experienceOptions {
    padding: 25px 15px 15px 15px;
    border-radius: 15px;
    border: 2px solid #fdfdfd;
    background-color: #4F5B62;
}

.optionsOuter {
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 10px;
}

.optionsOuter:hover {
    background-color: #232931;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.chosen {
    background-color: #232931;
    transition: background-color 0.3s ease;
}

.experienceFlexbox {
    display: flex;
    margin: auto;
    justify-content: center;
    padding: 0 20px;
}

.innerText {
    position: relative;
    margin-left: 50px;
    width: 500px;
    min-height: 200px;
    padding: 30px 20px 40px 20px;
    border-radius: 15px;
    background-color: #4F5B62;
    border: 2px solid #fdfdfd;
}


.innerTitle {
    font-size: 2.4em;
    padding-bottom: 5px;
}

.innerDates {
    color: #61dafb;
    padding-bottom: 20px;

}    

.innerDescription {
    text-align: left;
    padding: 5px 10px;
}

@media screen and (max-width: 600px) {
    .innerText {
        margin-left: 20px;
    }
    
    .innerTitle {
        font-size: 2em;
    }

    .innerDates {
        font-size: 0.9em;
    }

    .innerDescription {
        font-size: 0.8em;
    }
 }

 @media screen and (max-width: 460px) {
    .experienceFlexbox {
        display: flex;
        flex-direction: column;
    }

    .innerText {
        margin: auto;
        width: auto;
    }

    .experienceOptions {
        margin-bottom: 20px;
        padding: 15px 15px 5px 15px;
    }
 }

@media screen and (max-width: 425px) {
   .experienceTitle {
        font-size: 60px;
   }
}

@media screen and (max-width: 375px) {
    .innerText {
        padding: 30px 10px 40px 10px;
    }
    .innerDescription {
        padding: 5px 5px;
    }
 }

@media screen and (max-width: 319px) {
    .experienceTitle {
         font-size: 50px;
    }
 }