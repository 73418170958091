.contactGrid {
    display: grid;
    margin: auto;
    justify-content: center;
    grid-template-columns: 27vw 27vw;
}

.contactMe {
    font-size: 4vw;
    text-align: right;
    padding: 0vw 2vw 0.5vw 1vw;
}

.contacts {
    text-align: left;
    align-items: left;
}

.logo {
    width: 4vw;
    padding: 1vw 1vw;
    filter: invert(100%) sepia(3%) saturate(7387%) hue-rotate(224deg) brightness(106%) contrast(98%);
}

.logo:hover{
    filter: invert(35%) sepia(6%) saturate(957%) hue-rotate(159deg) brightness(93%) contrast(89%);
    transform: translate(0, -10px);
}

@media screen and (max-width: 1023px) {

    .contactGrid {
        grid-template-columns: 276px 276px;
    }
    .logo {
        padding: 10.23px 10.23px;
        width: 40.8px;
    }

    .contactMe {
        font-size: 40.9px;
    }
}

@media screen and (max-width: 560px) {

    .contactGrid {
        grid-template-columns: 276px;
    }

    .contactMe {
        padding: 0;
        text-align: center;
    }
}
  