.App {
  text-align: center;
  background-color: #232931;
  margin: 0;
}

.pointerGlow {
  position: fixed;
  z-index: 1002;
  inset: 0;
  pointer-events: none;
  display: block;
  background-attachment: fixed;
}

html {
  scroll-behavior: smooth;
}

/*
this is to remove the white bar at the top of the screen
*/
* { margin: 0; }

.body {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;

  margin: auto;
  
  color: #fdfdfd;
  width: auto;
  z-index: 5;
}

.blankSpaceTop {
  padding: 13vh;
}

.blankSpaceBottom {
  padding: 22vh 0;
}

.blankSpaceBottom2 {
  padding: 2vh 0;
}

.rohanAkki {
  font-size: 10vw;
  font-family: titleFont;
  padding-right: 4vw;
  text-shadow: 1vw 1vw #61dafb;
}

hr {
  color: #fdfdfd;
  size: 1em;
}

.App-link {
  color: #61dafb;
}

hr {
  width: 60%;
  margin: 0 auto;
  padding: 0;
}

@font-face {
  font-family: titleFont;
  src: url(MonsterBitesItalic-1Gy52.ttf);
}

@media screen and (max-width: 2000px) {
  .rohanAkki {
    font-size: 200px;
  }
}

@media screen and (max-width: 1450px) {
  .rohanAkki {
    font-size: 13.75vw;
  }
}

@media screen and (max-width: 1025px) {
  .rohanAkki {
    font-size: 140px;
  }
}

@media screen and (max-width: 530px) {
  .rohanAkki {
    font-size: 26vw;
  }
}
