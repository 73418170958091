.disappear {
  position: sticky;
  top: 0px;
  transition: background-color 0.3s ease;
  background-color: #2A2D34;
  
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
  z-index:1001;
}

.inverted {
  background-color: #4F5B62;
  box-shadow: 0 3px 5px 0.3px rgba(0,0,0,0.3);
}

.disappear.hide {
  top: -6rem;
}

.outerMid {
  width: 150px;
  height: 53px;
  position:relative;
}

div.links{
  display: grid;
  margin: auto;
  justify-content: center;
  grid-template-columns: repeat(4, 150px);
}

.links a {
  text-decoration: none;
  display: block;
  padding: 1rem 2rem 1rem 2rem;
  color: #fdfdfd !important;
  border-radius: 5px;
}

a.mid {
  display: block;
  position: relative;
}

/* Fade in */
a.mid::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #61dafb;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

a.mid:hover::after,
a.mid:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

a.mid {
  overflow: hidden;
}

a.mid::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

a.mid:hover::after,
a.mid:focus::after{
  transform: translate3d(0, 0, 0);
}



@media screen and (max-width: 650px) {
  .disappear {
      display: none;
  }
}