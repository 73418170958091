.aboutWrapper{
    text-align: left;
    padding: 10vh 0;
}

.aboutMe {
    font-size: 77px;
}

ul.aboutUl {
    padding: 0;
    justify-content: space-between;
    max-width: 800px;
    margin: auto;
}

.aboutText {    
    line-height: 24px;
}

.aboutUl {
  
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
 
    list-style: none;
    padding: 0rem 2rem 0rem 2rem;
}

.aboutUl li {
  
    text-decoration: none;
    display: block;
    
}

.aboutTotal {
    max-width: 400px;
    padding: 0;
}

.mePicture {
    max-width: 300px;
    padding: 0rem 0rem 0rem 0rem;
    border-radius: 10%;
    border: 2px solid #fdfdfd;
    margin: 0rem 1rem 0rem 1rem;
}

.innerAbout {
    scroll-margin-block-start: 25vh;
    scroll-margin-block-end: 25vh;
}

a {
    color: #61dafb;
}

a:visited {
    color: #61dafb;
}

.about2 {
    display: none;
}

@media screen and (max-width: 1440px) {
    .aboutTotal {
        padding: 0 2vw;
    }
}

@media screen and (max-width: 800px) {
    .mePicture {
        max-width: 250px;
    }
}

@media screen and (max-width: 750px) {
    .aboutUl {
        display: none;
    }
    .about2 {
        display: block;
    }
    .aboutMe {
        text-align: center;
    }
    .mePicture {
        margin: auto;
        display: block;
    }

    .aboutText {
        padding: 0 5vw;
        text-align: center;
    }
}